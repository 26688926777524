import React from 'react';
import './Cards.css';
import CardLink from './CardLink';

function BeautyCards() {
  return (
     <div className='cards'>
      <h1>
        Centros de Belleza y Cuidado Personal
      </h1>
      <p>
        Les compartimos algunos sitios que podrían serles útiles para prepararse antes de la boda.
      </p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
              <CardLink
              src='LePetitSalon.jpg'
              text='Le Petit Salon'
              label='Peluqueria'
              description='Especialistas en Peluqueria para Mujer.'
              url='https://maps.app.goo.gl/3pTYEEKFS4TwRmrY7'
              />
              <CardLink
              src='Arpias.png'
              text='Arpías'
              label='Manicura/Pedicura'
              description='Cadena de manicura y pedicura. Cuentan con varias ubicaciones en toda la ciudad. Reservad con antelación.'
              url='https://www.somosarpias.com'
              />
              <CardLink
              src='HelloNails.jpg'
              text='Hello Nails'
              label='Manicura/Pedicura'
              description='Cadena de especialistas en uñas, manos y pies. Reserven con tiempo. Están por toda la ciudad.'
              url='https://hellonails.com/en/our-centers/'
              />
            <CardLink
              src='MDPerruquers.jpg'
              text='MD Perruquers'
              label='Peluqueria de Hombres'
              description='Barberia y Peluqueria de hombres en Les Corts.'
              url='https://maps.app.goo.gl/u17jWjF2vh3WzpB77'
            />
            <CardLink
              src='AIRE.jpg'
              text='AIRE Spa'
              label='SPA'
              description='SPA icónico de Barcelona, situado en el Born.'
              url='https://maps.app.goo.gl/r1G9hb64VGP81cBH6'
            />
            <CardLink
              src='TBBA.png'
              text='Beauty Bar Atelier'
              label='Cejas y Pestañas'
              description='Son espectaculares sus tratamientos de cejas y pestañas - ofrecen otros servicios tambien.'
              url='https://www.thebeautybaratelier.com'
            />
            <CardLink
              src='Maquillaje.jpg'
              text='Haircut Day'
              label='Peluquería y Maquillaje'
              description='Cadena de Belleza con servicios de Peluquería para Hombre y Mujer. Maquillistas profesionales.'
              url='https://haircutday.com/salon-de-belleza-barcelona'
            />
            <CardLink
              src='Imaging.jpeg'
              text='Imaging BCN'
              label='Peluquería y Maquillaje'
              description='Especialistas en Maquillaje para eventos. Hacen maquillajes a domicilio.'
              url='https://imagingbcn.es'
            />
            <CardLink
              src='rituels.jpg'
              text="Rituels d'Orient"
              label='SPA'
              description='SPA y Hammam, con servicios de masaje, faciales, y pedicura/manicura.'
              url='https://maps.app.goo.gl/Ex5MdA8ejkQP5cfXA'
            />
            <CardLink
              src='Tintoreria.jpg'
              text='Tintorería Solanes'
              label='Tintorería'
              description='Tintorería en Les Corts.'
              url='https://maps.app.goo.gl/D8NuHchTHA8earUN7'
            />
        </ul>
        </div>
      </div>
    </div>
  )
}

export default BeautyCards