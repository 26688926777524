import React from 'react';

function CardLink(props) {
  const imageName = props.src.split('.')[0]; // removes extension if needed

  const onClick = () => {
    if (props.url) {
      window.open(props.url, '_blank'); // Open the URL in a new tab
    }
  };

  return (
    <>
      <li className='cards__item' onClick={onClick}>
        <div className='cards__item__link'>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <picture>
              <source
                type="image/webp"
                srcSet={`/images/output/webp/${imageName}.webp`}
              />
              <source
                type="image/jpeg"
                srcSet={`
                  /images/output/resized/${imageName}-480.jpg 480w,
                  /images/output/resized/${imageName}-768.jpg 768w,
                  /images/output/resized/${imageName}-1200.jpg 1200w
                `}
                sizes="(max-width: 600px) 480px, (max-width: 992px) 768px, 1200px"
              />
              <img
                className='cards__item__img'
                src={`/images/${props.src}`} // fallback
                alt='Link Card'
                loading="lazy"
                onLoad={(e) => e.target.classList.add('loaded')}
              />
            </picture>
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            <p className='cards__item__p'>{props.description}</p>
          </div>
        </div>
      </li>
    </>
  );
}

export default CardLink;