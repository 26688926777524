import React from 'react';
import './Cards.css';
import CardLink from './CardLink';

function AccomodationCards() {
  return (
    <div className='cards'>
      <h1>Alojamientos</h1>
      <p>
        Hemos recopilado algunos hoteles céntricos y seguros. También les compartimos algunas opciones en Les Corts, el barrio donde vivimos. Al estar ya cerca de la fecha, las opciones empiezan a escasear… ¡así que reserven pronto!
      </p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardLink
              src='Derby.png'
              text='Gran Derby Suite'
              label='Francesc Macià'
              description='Hotel cercano a la plaza de Francesc Macià. Perfecto para quedarse la noche de la boda.'
              url='https://www.hotelgranderby.com/es'
            />
            <CardLink
              src='Melia.png'
              text='Meliá Hotels Barcelona'
              label='Francesc Macià'
              description='Consigue hasta un 25% de descuento. En el apartado "Código de Empresa", introduce 609081AEF. Plazas limitadas.'
              url='https://meliapro.melia.com/es/corporate'
            />
            <CardLink
              src='Primavera.png'
              text='Primavera Hostel'
              label="L'Eixample"
              description='Hostel céntrico y muy acogedor. Perfecto para quienes viajan solos.'
              url='https://www.booking.com/hotel/es/primavera-hostel'
            />
            <CardLink
              src='SB.png'
              text='SB Diagonal Mar'
              label='Fòrum'
              description='Hotel cerca de la playa del Fòrum. Aunque un poco lejos, está muy bien comunicado.'
              url='https://www.hoteldiagonalzero.com/es/'
            />
            <CardLink
              src='Barcelo.png'
              text='Hotel Barceló - Occidental Barcelona 1929'
              label='Plaza España'
              description='Hotel muy cercano a Plaza España. También lo recomendamos para la noche de la boda.'
              url='https://www.barcelo.com/es-es/occidental-barcelona-1929/'
            />
            <CardLink
              src='Princep.png'
              text='Booking.com - Inside Barcelona Príncep'
              label='Plaza España'
              description='Apartamentos privados. Tienen varias opciones con capacidad para 4, 3 o 2 adultos. Perfecto para viajes en grupo.'
              url='https://www.booking.com/hotel/es/vivobarcelona-apartments.es.html'
            />
            <CardLink
              src='Atenea.jpg'
              text='Atenea Barcelona'
              label='Les Corts'
              description='Hotel ubicado a 5 minutos andando del Corte Inglés Diagonal.'
              url='https://www.cityhotels.es/aparthotel-atenea-barcelona/'
            />
            <CardLink
              src='NHConstanza.jpg'
              text='Hotel NH - Constanza'
              label='Les Corts'
              description="Ubicado detrás del centro comercial L'Illa y a 10 minutos andando de Francesc Macià."
              url='https://www.nh-collection.com/en/hotel/nh-collection-barcelona-constanza'
            />
            <CardLink
              src='Castellnou.png'
              text='Hotel Catalonia Castellnou'
              label='Sarrià'
              description='Opción cómoda en la zona alta de la ciudad.'
              url='https://maps.app.goo.gl/FG3tWSKGsZ5HsgmH6'
            />
            <CardLink
              src='NHLesCorts.jpg'
              text='Hotel NH - Les Corts'
              label='Les Corts'
              description='Una de las opciones más cómodas para estar cerca de nosotros.'
              url='https://www.nh-hotels.com/es/hotel/nh-barcelona-les-corts'
            />
            <CardLink
              src='Silken.png'
              text='Silken Sant Gervasi'
              label='Sarrià'
              description='Hotel en una zona residencial de Barcelona. Muy seguro y tranquilo, y bien comunicado con la L7.'
              url='https://maps.app.goo.gl/u42V3AcTmUHpUB569'
            />
            <CardLink
              src='Rigoletto.png'
              text='Hotel Rigoletto'
              label='Les Corts'
              description='Hotel ubicado a 5 minutos a pie del Corte Inglés Diagonal.'
              url='https://maps.app.goo.gl/jho68Ueyk2k2pGFu5'
            />
            <CardLink
              src='MiCampus.png'
              text='MiCampus Barcelona'
              label='Fòrum'
              description='Ubicado en Diagonal Mar. Aunque un poco lejos, está muy bien comunicado. Perfecto para quienes viajan solos.'
              url='https://www.booking.com/hotel/es/micampus-barcelona'
            />
            <CardLink
              src='POLHotel.jpg'
              text='Pol & Grace Hotel'
              label='Gràcia'
              description='Hotel curado por THE OVER en Gràcia. Para quienes buscan un espacio creativo.'
              url='https://www.polgracehotel.es/'
            />
            <CardLink
              src='Atiram.png'
              text='Tres Torres Atiram Hotel'
              label='Sarrià'
              description='Hotel en una zona tranquila de la ciudad. Comunicado con el bus H6 y a 20 minutos andando de la Diagonal.'
              url='https://maps.app.goo.gl/sriwkPuuX6dBta9X8'
            />
            <CardLink
              src='StMoritz.jpg'
              text='Hotel St. Moritz'
              label="L'Eixample"
              description='Hotel céntrico, muy cerca de Passeig de Gràcia.'
              url='https://www.hcchotels.com/en/st-moritz/'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AccomodationCards;
