import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Venue from './components/pages/Venue';
import Barcelona from './components/pages/Barcelona';
import Accomodation from './components/pages/Accomodation';
import Beauty from './components/pages/Beauty';
import Restaurants from './components/pages/Restaurants';
import Subscribe from './components/pages/Subscribe';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/venue' element={<Venue />} />
        <Route path='/barcelona' element={<Barcelona />} />
        <Route path='/accomodation' element={<Accomodation />} />
        <Route path='/beauty' element={<Beauty />} />
        <Route path='/restaurants' element={<Restaurants />} />
        <Route path='/subscribe' element={<Subscribe />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;