import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  const imageName = props.src.split('.')[0]; // removes extension if needed

  return (
    <li className='cards__item'>
      <Link className='cards__item__link' to={props.path}>
        <figure className='cards__item__pic-wrap' data-category={props.label}>
          <picture>
            <source
              type="image/webp"
              srcSet={`/images/output/webp/${imageName}.webp`}
            />
            <source
              type="image/jpeg"
              srcSet={`
                /images/output/resized/${imageName}-480.jpg 480w,
                /images/output/resized/${imageName}-768.jpg 768w,
                /images/output/resized/${imageName}-1200.jpg 1200w
              `}
              sizes="(max-width: 600px) 480px, (max-width: 992px) 768px, 1200px"
            />
            <img
              className='cards__item__img'
              src={`/images/${props.src}`} // fallback
              alt='Redirection Card'
              loading="lazy"
              onLoad={(e) => e.target.classList.add('loaded')}
            />
          </picture>
        </figure>
        <div className='cards__item__info'>
          <h5 className='cards__item__text'>{props.text}</h5>
        </div>
      </Link>
    </li>
  );
}

export default CardItem;
