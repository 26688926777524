import React from 'react';
import './HeroSection.css';
import { Button } from './Button';
import './Button.css';
import ImageSlider from './ImageSlider';

function HeroSection() {
  const images = [
    'images/DSC08717BYN.jpg',
    'images/DSC08780BYN.jpg',
    'images/DSC08828BYN.jpg',
    'images/DSC08588BYN.jpg',
    'images/DSC08856BYN.jpg'
  ];
  
  return (
     <div className='hero-container'>
      <ImageSlider images={images} />
      <h1>Save the date
      <br/>
      09.05.2025
      </h1>
      <Button buttonStyle='medium--btn'>Confirma Aquí tu Asistencia</Button>
       <br/>
    </div>
  )
}

export default HeroSection