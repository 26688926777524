import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function HomeCards() {
  const onClick =()=>{
    window.open("https://maps.app.goo.gl/J1vzvBqjzTnirzrn7", '_blank'); // Open the URL in a new tab       
}
  return (
    <div className='cards'>
      <h1>
        Acompáñanos en un día muy especial...
      </h1>
      <h2>
        Estamos honrados de recibirte en la ciudad que llamamos nuestro hogar,
        en uno de los días más importantes de nuestras vidas. 
        Aquí encontrarás todos los detalles sobre nuestro gran día, 
        desde los planes para la ceremonia y la recepción hasta información sobre viajes y más. 
        ¡Será un honor poder celebrar nuestro amor rodeados de las personas que más queremos! 
        Gracias por ser parte de nuestra historia.
        <br />
        <img
          src="images/Chava Borjas Logo B.png"
          alt="Vic & Nohe Logo"
          className="cards-logo"
        />
      </h2>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='CanRibas.jpg'
              text='Informacion general sobre la ubicación, horario, y transporte.'
              label='Can Ribas de Montbui'
              path='/venue'
            />
            <CardItem
              src='SagradaFamilia.jpg'
              text='Que hacer en Barcelona, seguridad y desplazamiento en la ciudad.'
              label='Barcelona'
              path='/barcelona'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='WHotel.jpg'
              text='Barrios de Barcelona, hoteles, y airbnbs.'
              label='Alojamiento'
              path='/accomodation'
            />
            <CardItem
              src='BarCanete.jpg'
              text='Restaurantes recomendados para comida, cena, y desayuno. Sitios curiosos para tapear.'
              label='Restaurantes'
              path='/restaurants'
            />
            <CardItem
              src='Salon.jpg'
              text='Peluquerías, barberías, tintorerías, centros de spa y salones de belleza.'
              label='Belleza para él y ella'
              path='/beauty'
            />
          </ul>
        </div>
      </div>
      <p>
        Y para poder ubicar todas nuestras recomendaciones, te dejamos un enlace a nuestra lista de google maps. ¡Esperamos te sea muy útil!
      <br />
      <p1 onClick={onClick}>Enlace a nuestras recomendaciones</p1>
      </p>
    </div>
  );
}

export default HomeCards;